<template>
    <div class="login flex-column flex-ai-center">
        <p class="title">RockySaaS培训平台</p>

        <div class="input-box flex-start">
            <input type="text" v-model="phone" placeholder="请输入手机号" autocomplete="false">
        </div>
        <div class="input-box flex-row flex-between flex-ai-center">
            <input type="text" placeholder="请输入验证码" v-model="code" autocomplete="off">
            <div class="code-button" @click="sendCode">{{buttonText}}</div>
        </div>

        <div class="login-btn" @click="handleLogin">注册/登录</div>

        <p class="agree flex-row flex-jc-center">
            <!-- <input type="radio" v-model="checked" /> -->
            <van-checkbox v-model="checked">
                <template #icon="props">
                    <img width="14" v-if="props.checked" src="../img/checked.png" />
                    <div style="width: 15px;height: 15px;border: 1px solid #DFF0FF;border-radius:50%;" v-else></div>
                </template>
            </van-checkbox>
            <span style="padding-left:5px;">注册/登录即同意<span @click="$router.push('/protocol')">《RockySaaS用户协议》</span></span>
        </p>
    </div>
</template>

<script>
import { login, sendCode } from '@/util/api'
import { storage } from '@/storage'
import { Toast } from 'vant'

export default {
    data () {
        return {
            phone: '',
            code: '',
            checked: false, // 协议勾选
            buttonText: '发送动态码',
            countDown: 60,
            timer: undefined
        }
    },
    methods:{
        sendCode () {
            if (this.timer) return
            if (!this.phone) {
                Toast.fail('请先输入手机号')
                return
            }
            const params = {
                apply_phone: this.phone
            }
            sendCode(params).then(() => {
                Toast.success('发送成功')
                this.timer = setInterval(() => {
                    if (this.countDown <= 1) {
                        this.countDown = 60
                        this.buttonText = '发送动态码'
                        clearInterval(this.timer)
                        this.timer = undefined
                        return
                    }
                    this.buttonText = `${--this.countDown}s`
                }, 1000)
            })
        },
        handleLogin () {
            if (!this.checked) {
                Toast.fail('请先勾选用户协议')
                return
            }
            if (!this.phone) {
                Toast.fail('请先输入手机号')
                return
            }
            if (!this.code) {
                Toast.fail('请先输入验证码')
                return
            }
            const postData = {
                apply_phone: this.phone,
                code: this.code
            }
            login(postData).then(res => {
                storage.set('token', res.data.token)
                if (res.data.redirectUrl) {
                    location.href = res.data.redirectUrl
                    return
                }
                const redirect = this.$route.query.redirect
                if (redirect) {
                    this.$router.go(-1)
                } else {
                    this.$router.push('/')
                }
                this.$emit('onlogin')
            })
        }
    }
}
</script>


<style scoped>
.agree{
    font-size: .24rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #DFF0FF;
    text-align: center;
    margin-top: 1rem;
}
.code-button {
    width: 120px;
    height: 33px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #DFF0FF;
    line-height: 33px;
    text-align: right;
    padding-right: 10px;
}
.login{
    padding: 0 0.4rem;
    background: url('../img/login-bg.png') no-repeat;
    background-size: 100%;
    width: 7.5rem;
    height: 12.07rem;
    box-sizing: border-box;
    overflow: hidden;
    
}
.title{
    text-align: center;
    font-size: .36rem;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 1.54rem;
}
.input-box {
    width: 5.40rem;
    height: 1.16rem;
    background: rgba(0, 43, 102, 0.3);
    border-radius: .58rem;
    border: 1px solid #CBE1FF;
    /* font-size: .24rem; */
    /* text-align: center; */
    margin-top: .2rem;
    padding-left: .25rem;
}
.input-box input {
    width: 5.40rem;
    height: 1.16rem;
    color: #DFF0FF;
    background: none;
    /* border-radius: .58rem; */
    border: none;
    font-size: .24rem;
    /* text-align: center; */
}
::placeholder{
    font-size: 0.3rem;
    color: #DFF0FF;
}
.login-btn{
    width: 5.40rem;
    height: 1.16rem;
    background: #E4F0FF;
    border-radius: .58rem;
    font-size: .26rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #0D6CD0;
    text-align: center;
    margin: 1.14rem auto 0 auto;
    line-height: 1.16rem;
}
</style>